import Link from 'next/link'
import LogoImage from '../../public/bulldogjob-logo.svg'

interface IProps {
  className?: string
}

const Logo = ({ className }: IProps) => (
  <div className={`${className} -ml-4 `}>
    <Link href='/' prefetch={false}>
      <a>
        <img
          src={LogoImage.src}
          alt='Bulldogjob'
          width='216'
          height='57'
          className='w-60 mt-1.5 md:mt-0 md:w-72 block'
        />
      </a>
    </Link>
  </div>
)

export default Logo
