import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAnalyticsPush } from '@/context/analyticsContext'

const Anayltics = () => {
  const { pushEvent } = useAnalyticsPush()
  const router = useRouter()

  useEffect(() => {
    pushEvent({
      event: 'pageview',
    })
  }, [router.asPath])
  return null
}

export default Anayltics
